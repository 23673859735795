import { Instance, types } from 'mobx-state-tree'

export const SmartLinkUrl = types.model({
  uuid: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  userSlug: types.maybeNull(types.string),
  platformSlug: types.maybeNull(types.string),
})

export type ISmartLinkUrl = Instance<typeof SmartLinkUrl>
