import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

import { SERVICE_NAME } from '../../../constants'
import { IAnalytics } from '../../../models/analyticsDistribution/Analytics.model'

const defaultFragment = `
    date
    userUuid
    assetUuid
    releaseUuid
    store
    streams
    countryCode
    offlinePlays
    totalPlays
    multiplier
    earningEstimate
`

export enum ANALYTICS_GROUP_BY {
  COUNTRY_CODE = 'countryCode',
  STORE = 'store',
  RELEASE_UUID = 'releaseUuid',
  ASSET_UUID = 'assetUuid',
  DATE = 'date',
}

export interface IGetAnalyticsProps {
  filters: {
    assetUuid?: string
    releaseUuid?: string
    userUuid?: string
    store?: string
    countryCode?: string
    startDate?: string
    endDate?: string
  }
  groupBy?: ANALYTICS_GROUP_BY[]
}

export type IGetAnalyticsResponse = IGenericResponse<'analyticsData', IAnalytics[]>

export async function getAnalyticsData(
  props: IGetAnalyticsProps,
  fragment: string = defaultFragment
): Promise<IGetAnalyticsResponse> {
  const query = `
    query AnalyticsData($filters: AnalyticsFilters, $groupBy: [AnalyticsFiltersQueryBy!]) {
      analyticsData(filters: $filters, groupBy: $groupBy) {
              ${fragment}
          }
      }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IGetAnalyticsResponse>
}
