import { extractSubdomain } from '@epic-front/common/src/helpers/utils'
import { lazy } from 'react'
import { Navigate } from 'react-router-dom'
import { PERMISSIONS } from 'e2e-utils'
import { commonPublicAccountRoutes } from '../commonPublicAccountRoutes'
import { commonPublicRoutes } from '../commonPublicRoutes'

import { ISiteRouteList, RouteType } from '../types'
import { commonPrivateRoutes } from '../commonPrivateRoutes'

/**
 * LAZY LOAD ALL THE PAGES
 */

const MyAccountPage = lazy(() => import('../../pages/profile/MyAccountPage'))
const SongPitchLanding = lazy(() => import('../../pages/songpitch/SongPitchLanding'))
const PaymentSelect = lazy(() => import('../../pages/payments/PaymentSelect/PaymentSelectPage'))
const MonthlyReportPage = lazy(() => import('../../pages/payments/MonthlyReportPage'))
const PitchEditPage = lazy(() => import('../../pages/songpitch/label-pages/PitchEditPage'))

const PitchListPage = lazy(() => import('../../pages/songpitch/label-pages/PitchListPage'))
const PitchAnalyticsPage = lazy(() => import('../../pages/songpitch/label-pages/PitchAnalyticsPage'))
const VideosWitchPitchedSongsPage = lazy(() => import('../../pages/songpitch/label-pages/VideosPitchedPage'))
const VideosAddedToPitchesPage = lazy(() => import('../../pages/songpitch/channel-pages/VideosAddedToPitchesPage'))

const ChannelListSongpitchPage = lazy(() => import('../../pages/songpitch/channel-pages/ChannelListSongpitchPage'))
const PitchListForChannelsPage = lazy(() => import('../../pages/songpitch/channel-pages/PitchListForChannelsPage'))
const TaxFormPage = lazy(() => import('../../pages/payments/TaxFormPage/TaxFormPage'))

const routes: ISiteRouteList = {
  // root routes
  ROOT_HOME: {
    // this should be rendered a public route but listed in the AUTH_PROTECTED ones
    key: 'ROOT_HOME',
    name: 'Home',
    url: '/',
    exact: true,
    element: <Navigate to="/home" />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  // auth protected routes
  DASHBOARD: {
    url: '/dashboard',
    name: 'Dashboard/Analytics',
    key: 'DASHBOARD',
    element: <PitchAnalyticsPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  PITCH_EDIT: {
    key: 'PITCH_EDIT',
    url: '/pitch-edit',
    name: 'Pitch New',
    element: <PitchEditPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_LABEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  CHANNEL_LIST: {
    url: '/channels-list',
    name: 'My Channels',
    key: 'CHANNEL_LIST',
    element: <ChannelListSongpitchPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_CHANNEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  PITCH_LIST: {
    url: '/pitches-list',
    key: 'PITCH_LIST',
    name: 'Pitch List',
    element: <PitchListForChannelsPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_CHANNEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  VIDEO_LIST: {
    url: '/videos',
    name: 'Videos',
    key: 'VIDEO_LIST',
    element: <VideosWitchPitchedSongsPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_LABEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  VIDEO_ADDED_LIST: {
    url: '/videos-added',
    name: 'Videos',
    key: 'VIDEO_ADDEd_LIST',
    element: <VideosAddedToPitchesPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_CHANNEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  SONG_LIST: {
    url: '/songs',
    name: 'Pitched Songs',
    key: 'SONG_LIST',
    element: <PitchListPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_LABEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MONTHLY_REPORT: {
    url: '/earnings/monthly-report',
    name: 'Payment Report',
    key: 'MONTHLY_REPORT',
    element: <MonthlyReportPage />,
    permissionsOr: [PERMISSIONS.SONGPITCH.PERFORM_LABEL],
    routeType: RouteType.AUTH_PROTECTED,
  },
  MY_ACCOUNT: {
    url: '/account/my-account',
    name: 'My Account',
    key: 'MY_ACCOUNT',
    element: <MyAccountPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  PAYMENT_SELECT: {
    url: '/payment-select',
    name: 'Payment Select',
    key: 'MY_ACCOUNT',
    element: <PaymentSelect />,
    routeType: RouteType.AUTH_PROTECTED,
  },
  TAX_FORMS: {
    key: 'TAX_FORMS',
    url: '/tax-forms',
    name: 'Payment Setup',
    element: <TaxFormPage />,
    routeType: RouteType.AUTH_PROTECTED,
  },

  // public routes
  HOME: {
    url: '/home',
    name: 'Dashboard',
    key: 'HOME',
    element: extractSubdomain() ? <SongPitchLanding /> : <SongPitchLanding />,
    routeType: RouteType.PUBLIC,
  },
}

export const SONGPITCH_ROUTES = {
  ...commonPublicRoutes,
  ...routes,
  ...commonPrivateRoutes(routes),
  ...commonPublicAccountRoutes,
}
