import { SERVICE_NAME } from '../../../constants'
import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

interface IAssignUserToTracksProps {
  userUuid: string
  trackUuids: string[]
}

export type IAssignUserToTracksResponse = IGenericResponse<'assignUserToTracks', boolean>

export async function assignUserToTracks(props: IAssignUserToTracksProps): Promise<IAssignUserToTracksResponse> {
  const query = `
    mutation assignUserToTracks ($userUuid: String!, $trackUuids: [String!]!) {
      assignUserToTracks (
        userUuid: $userUuid,
        trackUuids: $trackUuids
      ) 
    }
  `
  return clientWrapper(SERVICE_NAME.ASSETS, query, props) as Promise<IAssignUserToTracksResponse>
}
