import { Instance, cast, flow, types } from 'mobx-state-tree'
import moment from 'moment'

import { Pagination } from '../general/Pagination.model'
import {
  getChartMetricPlaylists,
  IGetChartMetricPlaylistsProps,
  IGetChartMetricPlaylistsResponse,
} from '../../api/assets-api/chartMetric/chartMetricPlaylists'
import { ChartMetric } from './ChartMetric.model'
import { CM_PLAYLIST_PLATFORM_FILTER } from '../../constants'

export const ChartMetricList = types
  .model({
    list: types.array(ChartMetric),
  })
  .volatile(() => ({
    loading: false,
    pagination: Pagination.create({ totalItems: 1 }),

    searchFilter: '',
    userUuidsFilter: [] as string[],
    playlistIdFilter: '',
    trackUuidFilter: '',
    releaseUuidFilter: '',
    platformFilter: null as CM_PLAYLIST_PLATFORM_FILTER | null,
    startDateFilter: null as Date | null,
    endDateFilter: null as Date | null,
  }))
  .actions(self => ({
    setSearchFilter(search: string) {
      self.searchFilter = search
    },
    setUserUuidsFilter(uuids: string[]) {
      self.userUuidsFilter = uuids
    },

    setPlaylistIdFilter(id: string) {
      self.playlistIdFilter = id
    },
    setTrackUuidFilter(id: string) {
      self.trackUuidFilter = id
    },
    setReleaseUuidFilter(releaseUuid: string) {
      self.releaseUuidFilter = releaseUuid
    },
    setPlatformFilter(platform: CM_PLAYLIST_PLATFORM_FILTER | null) {
      self.platformFilter = platform
    },
    setStartDateFilter(startDate: Date | null) {
      self.startDateFilter = startDate
    },
    setEndDateFilter(endDate: Date | null) {
      self.endDateFilter = endDate
    },
  }))

  .actions(self => ({
    load: flow(function* () {
      try {
        self.loading = true

        const variables: IGetChartMetricPlaylistsProps = {
          pagination: self.pagination.allQueryParams,
          filters: {
            ...(self.searchFilter && { search: self.searchFilter }),
            ...(self.userUuidsFilter.length > 0 && { userUuids: self.userUuidsFilter }),
            ...(self.releaseUuidFilter && { releaseUuid: self.releaseUuidFilter }),
            ...(self.playlistIdFilter && { playlist_id: self.playlistIdFilter }),
            ...(self.trackUuidFilter && { trackUuid: self.trackUuidFilter }),
            ...(self.platformFilter && { platform: self.platformFilter }),
            ...(self.startDateFilter && { startDateAdded: moment(self.startDateFilter).format('YYYY-MM-DD') }),
            ...(self.endDateFilter && { endDateAdded: moment(self.endDateFilter).format('YYYY-MM-DD') }),
          },
        }

        const resp: IGetChartMetricPlaylistsResponse = yield getChartMetricPlaylists(variables)

        if (resp && resp.data.data) {
          self.list = cast(resp.data.data.chartMetricPlaylists.playlists)
          self.pagination.setTotalItems(resp.data.data.chartMetricPlaylists.total)
        }
      } catch (err) {
        console.error(err)
      } finally {
        self.loading = false
      }
    }),
  }))

export type IChartMetricList = Instance<typeof ChartMetricList>
