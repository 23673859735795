import { Instance, types } from 'mobx-state-tree'

import { ImageBasic } from '../general/ImageBasic.model'
import { SmartLinkUrl } from './SmartLinkUrl.model'

export const SmartLinkProfileBasic = types.model({
  uuid: types.maybeNull(types.string),
  userUuid: types.maybeNull(types.string),
  slug: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  bio: types.maybeNull(types.string),
  profileImage: types.maybeNull(ImageBasic),
  bannerImage: types.maybeNull(ImageBasic),
  platformUrls: types.array(SmartLinkUrl),
})

export type ISmartLinkProfileBasic = Instance<typeof SmartLinkProfileBasic>
