import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { ISmartLinkProfileBasic } from '../../../models/smartlink/SmartLinkProfileBasic.model'

import { smartlinkProfileFragment } from '../fragments'

export type IUpdateSmartLinkProfileProps = {
  uuid: string
  userUuid: string
  slug: string
  name: string
  bio?: string
  profileImage: string | null
  bannerImage: string | null
}

export type IUpdateSmartLinkProfileResponse = IGenericResponse<'updateSmartLinkProfile', ISmartLinkProfileBasic>

export async function updateSmartLinkProfile(
  data: IUpdateSmartLinkProfileProps,
  fragment: string = smartlinkProfileFragment
): Promise<IUpdateSmartLinkProfileResponse> {
  const query = `
  mutation updateSmartLinkProfile($data: SmartLinkProfileInput!) {
      updateSmartLinkProfile(data: $data) {
          ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { data })) as IUpdateSmartLinkProfileResponse
}
