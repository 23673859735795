import moment from 'moment'
import { Button, ButtonGroup } from 'react-bootstrap'

export type dateOption = {
  title: string
  date: Date | null
}

export const DatePickerGroup = ({
  date,
  options,
  onChange,
}: {
  date: Date | null
  options: dateOption[]
  onChange?: (date: Date | null) => void
}): JSX.Element => {
  return (
    <ButtonGroup aria-label="Date Range">
      {options.map(option => (
        <Button
          key={option.title}
          variant="outline-secondary-900"
          active={moment(date).isSame(option.date, 'D')}
          onClick={() => (onChange ? onChange(option.date) : null)}
        >
          {option.title}
        </Button>
      ))}
    </ButtonGroup>
  )
}
