import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { ISmartLinkProfileBasic } from '../../../models/smartlink/SmartLinkProfileBasic.model'

import { smartlinkProfileFragment } from '../fragments'

export type ISmartLinkProfileResponse = IGenericResponse<'smartlinkProfile', ISmartLinkProfileBasic>

export async function smartlinkProfile(
  userUuid: string,
  fragment: string = smartlinkProfileFragment
): Promise<ISmartLinkProfileResponse> {
  const query = `query smartlinkProfile($userUuid:String!){
    smartlinkProfile(userUuid:$userUuid) {
      ${fragment}
    }
  }`

  return (await clientWrapper(SERVICE_NAME.USERS, query, { userUuid })) as ISmartLinkProfileResponse
}
