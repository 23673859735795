import { observer } from 'mobx-react-lite'
import { Button, Col, Form, Row } from 'react-bootstrap'
import { PERMISSIONS } from 'e2e-utils'

import { useStore } from '@hooks'

import { IMAGE_TYPE } from '@epic-front/common'

import { deleteImage, IDeleteImageResponse } from '@epic-front/common/src/api/users-api/other/deleteImage'

import { ISmartLinkProfile } from '@epic-front/common/src/models/smartlink/SmartLinkProfile.model'

import { ImageUpload } from '@blocks'

import { IImageBasic } from '@epic-front/common/src/models/general/ImageBasic.model'

type IProps = {
  smartlinkProfile: ISmartLinkProfile
}

const SmartLinkProfileUpdate = ({ smartlinkProfile }: IProps): JSX.Element => {
  const { auth } = useStore()

  const handleProfilePictureUpload = async (imageData: IImageBasic) => {
    smartlinkProfile.setProfileImage(null)
    smartlinkProfile.setProfileImageUuid(imageData.uuid ?? '')
    await smartlinkProfile.update()
  }

  const handleProfilePictureDelete = async () => {
    const profilePictureImageUuid = smartlinkProfile.getProfileUrlUuid()

    if (profilePictureImageUuid) {
      const resp: IDeleteImageResponse = await deleteImage(IMAGE_TYPE.SMART_LINK_PROFILE, profilePictureImageUuid)
      if (resp?.data.data?.deleteImage) {
        smartlinkProfile.setProfileImage(null)
        smartlinkProfile.setProfileImageUuid('')

        smartlinkProfile.update()
      }
    }
  }

  const handleBannerPictureUpload = async (imageData: IImageBasic) => {
    smartlinkProfile.setBannerImage(null)
    smartlinkProfile.setBannerImageUuid(imageData.uuid ?? '')
    await smartlinkProfile.update()
  }

  const handleBannerPictureDelete = async () => {
    const bannerPictureImageUuid = smartlinkProfile.getBannerUrlUuid()

    if (bannerPictureImageUuid) {
      const resp: IDeleteImageResponse = await deleteImage(IMAGE_TYPE.SMART_LINK_BANNER, bannerPictureImageUuid)

      if (resp?.data.data?.deleteImage) {
        smartlinkProfile.setBannerImage(null)
        smartlinkProfile.setBannerImageUuid('')
        smartlinkProfile.update()
      }
    }
  }

  const isClient = auth.hasPermission(PERMISSIONS.SMARTLINK.MANAGE_SELF)

  return (
    <Row>
      <Col xs={12} xl={6}>
        <Form>
          <hr className="d-md-none mb-3" />
          <h4>Profile details</h4>
          <p className="text-gray-500 mb-3 small">Edit your profile details; Name, Slug and Bio.</p>
          <Form.Group controlId={`${smartlinkProfile.userUuid}-smartlink-name`} className="mb-3">
            <h5 className="mb-1 fs-6">Name</h5>
            <p className="text-gray-500 small fw-normal mb-2">This will be the main title of your page.</p>
            <Form.Control
              type="text"
              disabled={smartlinkProfile.loading}
              defaultValue={smartlinkProfile.name || ''}
              onChange={event => smartlinkProfile.setName(event.target.value)}
            />
          </Form.Group>
          <Form.Group controlId={`${smartlinkProfile.userUuid}-smartlink-slug`} className="mb-3">
            <h5 className="mb-1 fs-6">URL / Slug</h5>
            <p className="text-gray-500 small fw-normal mb-2">
              The extension added to the end of www.songlink.com/<b>xxxxxx</b>
            </p>
            <Form.Control
              type="text"
              disabled={isClient || smartlinkProfile.loading}
              defaultValue={smartlinkProfile.slug || ''}
              onChange={event => smartlinkProfile.setSlug(event.target.value)}
            />
            {isClient && (
              <Form.Text className="text-gray-500">
                <p className="my-1">Please contact our operations team for the change of the URL.</p>
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group controlId={`${smartlinkProfile.userUuid}-smartlink-bio`} className="mb-3">
            <h5 className="mb-1 fs-6">Bio / Information</h5>
            <p className="text-gray-500 small fw-normal mb-2">
              Information or bio description about your brand or yourself.
            </p>
            <Form.Control
              as="textarea"
              rows={5}
              disabled={smartlinkProfile.loading}
              defaultValue={smartlinkProfile.bio || ''}
              onChange={event => smartlinkProfile.setBio(event.target.value)}
            />
          </Form.Group>
        </Form>
      </Col>
      <Col xs={12} xl={3}>
        <h4 className="">Avatar image</h4>
        <p className="text-gray-500 mb-3 small">The image shown at the top of your page.</p>
        <div style={{ maxWidth: '330px' }}>
          <ImageUpload
            type={IMAGE_TYPE.SMART_LINK_PROFILE}
            previewUrl={smartlinkProfile.profileImage?.downloadUrl ?? undefined}
            minWidth={250}
            minHeight={250}
            onDelete={handleProfilePictureDelete}
            onUploadComplete={handleProfilePictureUpload}
          />
          <div className="alert alert-info mt-2 small text-center">
            Minimum size: <b>250 x 250px</b>
          </div>
        </div>
      </Col>
      <Col xs={12} xl={3}>
        <h4>Background image</h4>
        <p className="text-gray-500 small fw-normal mb-2">
          This image will appear in the top part of the page as a background cover image.
        </p>
        <div style={{ maxWidth: '330px' }}>
          <ImageUpload
            type={IMAGE_TYPE.SMART_LINK_BANNER}
            previewUrl={smartlinkProfile.bannerImage?.downloadUrl ?? undefined}
            minWidth={1280}
            minHeight={640}
            onDelete={handleBannerPictureDelete}
            onUploadComplete={handleBannerPictureUpload}
          />
          <div className="alert alert-info mt-2 small text-center">
            Minimum size: <b>1280 x 640px</b>
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <Button
          size="lg"
          className="btn my-2"
          variant="success"
          disabled={smartlinkProfile.loading}
          onClick={() => smartlinkProfile.update()}
        >
          <i className="uil-check fs-5 me-1" />
          Update details
        </Button>
      </Col>
    </Row>
  )
}

export default observer(SmartLinkProfileUpdate)
