import moment from 'moment'
import { DatePickerGroup, DateRangePicker } from '@blocks'
import { dateOption } from './DatePickerGroup'

export const DateRangePickerGroup = ({
  startDate,
  endDate,
  options,
  onChangeStartDate,
  onChangeEndDate,
  minDate,
}: {
  startDate: Date | null
  endDate: Date | null
  options: dateOption[]
  onChangeStartDate?: (date: Date | null) => void
  onChangeEndDate?: (date: Date | null) => void
  minDate?: Date
}): JSX.Element => {
  return (
    <div className="d-flex gap-2 flex-wrap flex-xxl-nowrap">
      <DatePickerGroup
        date={startDate}
        options={options}
        onChange={date => (onChangeStartDate ? onChangeStartDate(date) : null)}
      />
      <DateRangePicker
        startDate={startDate}
        endDate={endDate}
        onChangeStartDate={date => (onChangeStartDate ? onChangeStartDate(date) : null)}
        onChangeEndDate={date => (onChangeEndDate ? onChangeEndDate(date) : null)}
        minDate={minDate}
        active={options.every(option => !moment(option.date).isSame(startDate, 'D'))}
      />
    </div>
  )
}
