import { useCallback, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import classNames from 'classnames'

import './richTextStyle.scss'

import { useEditor, EditorContent, Editor, Node } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'

import Color from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Underline from '@tiptap/extension-underline'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Paragraph from '@tiptap/extension-paragraph'
import Link from '@tiptap/extension-link'

const DivNodeExtension = Node.create({
  name: 'div',
  group: 'block',
  content: 'block*',

  parseHTML() {
    return [
      {
        tag: 'div[class]', // Matches <div> tags with class attributes
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', HTMLAttributes, 0] // Ensures classes are preserved
  },

  addAttributes() {
    return {
      class: {
        default: null,
      },
    }
  },
})

const ParagraphExtension = Paragraph.extend({
  parseHTML() {
    return [
      {
        tag: 'p[class]', // Matches <div> tags with class attributes
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', HTMLAttributes, 0] // Ensures classes are preserved
  },

  addAttributes() {
    return {
      class: {
        default: null,
      },
    }
  },
})

const extensions = [
  StarterKit.configure({
    heading: {
      levels: [1, 2, 3],
    },
  }),
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: 'https',
  }),
  Color,
  Highlight.configure({ multicolor: true }),
  Underline,
  TextStyle,
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  ParagraphExtension,
  DivNodeExtension,
]

const Menu = ({ editor, className }: { editor: Editor; className?: string }) => {
  const [preview, setPreview] = useState(true)

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href
    // eslint-disable-next-line no-alert
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) return

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()
      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
  }, [editor])

  const showSource = () => {
    editor?.commands.setContent(`<textarea>${editor.getHTML()}</textarea>`)
  }

  const showHTML = () => {
    editor?.commands.setContent(editor.getText())
  }

  const togglePreviewHTML = () => {
    if (preview) {
      showSource()
      setPreview(false)
      return
    }

    showHTML()
    setPreview(true)
  }

  return (
    <div
      className={classNames(
        'd-flex align-content-center gap-1 flex-wrap p-2 border-bottom border-1 bg-gray-50',
        className
      )}
    >
      <OverlayTrigger placement="top" overlay={<Tooltip id="align-left">H1</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
          className={classNames(
            'btn px-1 border',
            editor.isActive('heading', { level: 1 }) ? 'bg-gray-200' : 'bg-white'
          )}
          title="H1"
        >
          <span className="fs-4">H1 </span>
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="align-left">H2</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
          className={classNames(
            'btn px-1 border',
            editor.isActive('heading', { level: 2 }) ? 'bg-gray-200' : 'bg-white'
          )}
          title="H2"
        >
          <span className="fs-4">H2 </span>
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="align-left">H3</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
          className={classNames(
            'btn px-1 border',
            editor.isActive('heading', { level: 3 }) ? 'bg-gray-200' : 'bg-white'
          )}
          title="H2"
        >
          <span className="fs-4">H3 </span>
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="bold">Bold</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          className={classNames('btn px-1 border', editor.isActive('bold') ? 'bg-gray-200' : 'bg-white')}
        >
          <i className="uil uil-bold fs-4" />
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="italic">Italic</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          className={classNames('btn px-1 border', editor.isActive('italic') ? 'bg-gray-200' : 'bg-white')}
        >
          <i className="uil uil-italic fs-4" />
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="bullet-list">Underline</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          className={classNames('btn px-1 border', editor.isActive('underline') ? 'bg-gray-200' : 'bg-white')}
        >
          <i className="uil uil-underline fs-4" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="align-left">Align Left</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('left').run()}
          className={classNames('btn px-1 border', editor.isActive({ textAlign: 'left' }) ? 'bg-gray-200' : 'bg-white')}
          title="Align Left"
        >
          <i className="uil uil-align-left fs-4" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="align-center">Align Center</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('center').run()}
          className={classNames(
            'btn px-1 border',
            editor.isActive({ textAlign: 'center' }) ? 'bg-gray-200' : 'bg-white'
          )}
          title="Align Center"
        >
          <i className="uil uil-align-center fs-4" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="align-right">Align Right</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().setTextAlign('right').run()}
          className={classNames(
            'btn px-1 border',
            editor.isActive({ textAlign: 'right' }) ? 'bg-gray-200' : 'bg-white'
          )}
          title="Align Right"
        >
          <i className="uil uil-align-right fs-4" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="bullet-list">Bullet List</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={classNames('btn px-1 border', editor.isActive('bulletList') ? 'bg-gray-200' : 'bg-white')}
          title="Ordered List"
        >
          <i className="uil uil-list-ul fs-4" />
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="ordered-list">Ordered List</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          className={classNames('btn px-1 border', editor.isActive('orderedList') ? 'bg-gray-200' : 'bg-white')}
        >
          <i className="uil-list-ui-alt fs-4" />
        </button>
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="add-link">Set Link</Tooltip>}>
        <button
          type="button"
          onClick={setLink}
          className={classNames('btn px-1 border', editor.isActive('link') ? 'bg-gray-200' : 'bg-white')}
        >
          <i className="uil-link-alt fs-4" />
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="add-link">Unset Link</Tooltip>}>
        <button
          type="button"
          onClick={() => editor.chain().focus().unsetLink().run()}
          className={classNames('btn px-1 border', editor.isActive('link') ? 'bg-gray-200' : 'bg-white')}
        >
          <i className="uil-link-broken fs-4" />
        </button>
      </OverlayTrigger>
      <OverlayTrigger placement="top" overlay={<Tooltip id="color">Text Color</Tooltip>}>
        <input
          type="color"
          onChange={event => editor.chain().focus().setColor(event.target.value).run()}
          value={editor.getAttributes('textStyle').color}
          className={classNames(
            'border p-1 rounded-1',
            editor.isActive('color', { color: '#ffa8a8' }) ? 'bg-gray-200' : 'bg-white'
          )}
          style={{ width: '2.2rem', height: '2.2rem' }}
        />
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="highlight">Background Color</Tooltip>}>
        <input
          type="color"
          onChange={event => editor.chain().focus().toggleHighlight({ color: event.target.value }).run()}
          value={editor.getAttributes('textStyle').highlight}
          className={classNames(
            'border p-1 rounded-1',
            editor.isActive('highlight', { color: '#ffa8a8' }) ? 'bg-gray-200' : 'bg-white'
          )}
          style={{ width: '2.2rem', height: '2.2rem' }}
        />
      </OverlayTrigger>

      <OverlayTrigger placement="top" overlay={<Tooltip id="remove-link">{preview ? 'HTML View' : 'Preview'}</Tooltip>}>
        <button
          type="button"
          onClick={togglePreviewHTML}
          className={classNames('btn px-1 border', preview ? 'bg-white' : 'bg-gray-200')}
        >
          <i className="uil uil-code fs-4" />
        </button>
      </OverlayTrigger>
    </div>
  )
}

const RichTextEditor = ({ value, onChange }: { value: string; onChange: (value: string) => void }) => {
  const editor = useEditor(
    {
      extensions,
      content: value,
      onUpdate: ({ editor: updatedEditor }) => {
        onChange(updatedEditor.getHTML())
      },
    },
    []
  )

  if (!editor) return null

  return (
    <div className="h-100 border rounded shadow-sm overflow-hidden">
      <Menu editor={editor} />
      {/* <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }} className="px-1">
        <Menu editor={editor} className="shadow-lg" />
      </BubbleMenu> */}
      <EditorContent editor={editor} />
    </div>
  )
}

export default observer(RichTextEditor)
