import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Button, Col, Form, Row } from 'react-bootstrap'
import Select from 'react-select'

import { socialPlatforms } from '@epic-front/common/src/api/users-api/smartlink/socialPlatforms'

import { ISmartLinkProfile } from '@epic-front/common/src/models/smartlink/SmartLinkProfile.model'

import { ITableColumn, Table } from '@blocks'

import { ISmartLinkUrl } from '@epic-front/common/src/models/smartlink/SmartLinkUrl.model'
import { VALID_URL_REGEX } from '@epic-front/common'

type IProps = {
  smartlinkProfile: ISmartLinkProfile
}

const SmartLinkSocialPlatformsLinks = ({ smartlinkProfile }: IProps): JSX.Element => {
  const [socialPlatformSlugs, setSocialPlatformSlugs] = useState<string[]>([])

  const [newLinkPlatformSlug, setNewLinkPlatformSlug] = useState<string>()
  const [newPlatformUrl, setNewPlatformUrl] = useState<string>()

  useEffect(() => {
    const getSocialPlatforms = async () => {
      try {
        const resp = await socialPlatforms()
        if (resp && resp?.data.data?.socialPlatforms) {
          setSocialPlatformSlugs(resp.data.data.socialPlatforms)
        }
      } catch (err) {
        console.error(err)
      }
    }

    getSocialPlatforms()
  }, [smartlinkProfile.userUuid])

  const getPlaceholder = () => {
    if (!newLinkPlatformSlug) {
      return 'Enter platform account name'
    }

    switch (newLinkPlatformSlug) {
      case 'Youtube':
        return 'https://www.youtube.com/@ChannelName'
      case 'Facebook':
        return 'https://www.facebook.com/pagename/'
      case 'TikTok':
        return 'https://www.tiktok.com/@profile.name'
      default:
        return 'Enter platform account name'
    }
  }

  const handleAddNewPlatformUrl = () => {
    if (!newPlatformUrl || VALID_URL_REGEX.test(newPlatformUrl)) {
      return
    }

    smartlinkProfile.createSmartLinkUrl(newLinkPlatformSlug || '', newPlatformUrl || '')
  }

  const columns: ITableColumn<ISmartLinkUrl>[] = [
    {
      Header: 'Platform',
      accessor: 'platformSlug',
    },
    {
      Header: 'Platform Url',
      accessor: 'url',
      headerCellClasses: 'text-center',
      cellClasses: 'text-nowrap text-center',
    },
  ]

  return (
    <>
      <Row>
        <Col xs={12}>
          <hr className="my-3" />
          <h4>Social Platform links</h4>
          <p className="text-gray-500 mb-3 small">Add the links to your Social Networks.</p>
          <Form>
            <Row className="mb-2">
              <Col xs={12} sm={4} className="mb-2">
                <Form.Group controlId={`${smartlinkProfile.userUuid}-smartlink-platform-slug`}>
                  <Form.Label>Platform name:</Form.Label>
                  <Select
                    className="react-select p-0 h-100"
                    classNamePrefix="react-select"
                    placeholder="Select platform"
                    options={socialPlatformSlugs.map(platform => ({ label: platform, value: platform }))}
                    onChange={inputPlatform => setNewLinkPlatformSlug(inputPlatform?.label)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6} className="mb-2">
                <Form.Group controlId={`${smartlinkProfile.userUuid}-smartlink-platform-url`}>
                  <Form.Label>Platform Url:</Form.Label>
                  <Form.Control
                    className="input"
                    type="text"
                    placeholder={getPlaceholder()}
                    disabled={smartlinkProfile.loading}
                    defaultValue={newPlatformUrl}
                    onChange={event => setNewPlatformUrl(event.target.value)}
                    isInvalid={newPlatformUrl ? !VALID_URL_REGEX.test(newPlatformUrl) : undefined}
                  />
                  <Form.Control.Feedback type="invalid" tooltip />
                </Form.Group>
              </Col>
              <Col xs={12} sm={2} className="mb-2 d-flex justify-content-end align-items-end">
                <Button
                  variant="success"
                  className="w-100"
                  disabled={
                    smartlinkProfile.loading ||
                    !newLinkPlatformSlug ||
                    !newPlatformUrl ||
                    !VALID_URL_REGEX.test(newPlatformUrl)
                  }
                  onClick={() => handleAddNewPlatformUrl()}
                >
                  <i className="uil-plus lh-1 fs-5 me-1" />
                  Add
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table<ISmartLinkUrl>
            tableClasses="table table-light table table-striped table-hover border"
            tableHeadClasses="table-dark"
            columns={columns}
            data={smartlinkProfile.platformUrls}
            isLoading={smartlinkProfile.loading}
            rowIdentifier={row => row.uuid || ''}
          />
        </Col>
      </Row>
    </>
  )
}

export default observer(SmartLinkSocialPlatformsLinks)
