import classNames from 'classnames'
import DatePicker from 'react-datepicker'
import { InputGroup, Button } from 'react-bootstrap'

type IDatePickerProps = {
  value: Date | null
  onChange: (date: Date | null) => void
  minDate?: Date
  maxDate?: Date
  inputClass?: string
  placeholderText?: string
  dateFormat?: string
  isClearable?: boolean
  isDisabled?: boolean
  showMonthYearPicker?: boolean
  showFullMonthYearPicker?: boolean
  showFourColumnMonthYearPicker?: boolean
  showTimeSelect?: boolean
}

const EpicDatePicker = (props: IDatePickerProps): JSX.Element => {
  const {
    value,
    onChange,
    dateFormat,
    minDate,
    maxDate,
    placeholderText,
    isClearable,
    isDisabled,
    showMonthYearPicker,
    showFullMonthYearPicker,
    showFourColumnMonthYearPicker,
    showTimeSelect,
  } = props

  const optionalProps = {
    ...(isDisabled && { disabled: isDisabled }),
    ...(dateFormat && { dateFormat }),
    ...(minDate && { minDate }),
    ...(maxDate && { maxDate }),
    ...(placeholderText && { placeholderText }),
    ...(isClearable && { isClearable }),
    ...(showMonthYearPicker && { showMonthYearPicker }),
    ...(showFullMonthYearPicker && { showFullMonthYearPicker }),
    ...(showTimeSelect && { showTimeSelect }),
    ...(showFourColumnMonthYearPicker && { showFourColumnMonthYearPicker }),
  }

  const { inputClass = '' } = props
  return (
    <InputGroup className="mb-0 flex-nowrap">
      <DatePicker
        selected={value}
        onChange={(inputDate: Date | null) => onChange(inputDate || null)}
        className={classNames('form-control epic-date-picker', inputClass)}
        {...optionalProps}
      />
      <Button type="submit" variant="oultine-gray" disabled className="input-group-text border">
        <i className="uil uil-calendar-alt" />
      </Button>
    </InputGroup>
  )
}

export default EpicDatePicker
