import { Form, InputGroup } from 'react-bootstrap'

export const SearchField = ({
  defaultValue,
  placeholder,
  className,
  minLength,
  onChange,
}: {
  defaultValue: string
  placeholder?: string
  className?: string
  minLength?: number
  onChange?: (value: string) => void
}): JSX.Element => {
  return (
    <>
      <InputGroup>
        <Form.Control
          type="text"
          aria-label="Search"
          minLength={minLength}
          className={className}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={e => (onChange ? onChange(e.target.value) : null)}
        />
        <InputGroup.Text id="basic-addon1" className="cursor-pointer">
          <i className="uil uil-search" />
        </InputGroup.Text>
      </InputGroup>
    </>
  )
}
