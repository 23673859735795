import { useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

import { STORES } from '@epic-front/common'
import { StoreIcon } from '../icons'

const StoreFilterButtonGroup = ({
  value,
  options,
  onChange,
}: {
  value?: STORES
  options: STORES[]
  onChange?: (store: STORES | null) => void
}): JSX.Element => {
  const [selectedStore, setSelectedStore] = useState<STORES | null>(value ?? null)

  const handleClick = (store: STORES | null) => {
    setSelectedStore(store)
    if (onChange) onChange(store)
  }

  return (
    <ButtonGroup className="d-flex flex-wrap flex-row gap-1">
      <Button
        variant="outline-secondary-900"
        className="flex-grow-0 rounded-1 shadow-xs"
        active={selectedStore === null}
        onClick={() => handleClick(null)}
      >
        All
      </Button>
      {options.map(store => (
        <Button
          key={store}
          variant="outline-secondary-900"
          className="flex-grow-0 rounded-1 shadow-xs"
          active={selectedStore === store}
          onClick={() => handleClick(store)}
        >
          <StoreIcon store={store} />
          {store}
        </Button>
      ))}
    </ButtonGroup>
  )
}

export default StoreFilterButtonGroup
