import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { ISmartLinkProfile, SmartLinkProfile } from '@epic-front/common/src/models/smartlink/SmartLinkProfile.model'

import { LoadingWrapper } from '@blocks'

import { IUserBasic } from '@epic-front/common/src/models/users/UserBasic.model'
import SmartLinkProfileUpdate from './SmartLinkProfileUpdate'
import SmartLinkSocialPlatformsLinks from './SmartLinkSocialPlatformsLinks'

type IProps = {
  user: IUserBasic
}

const SmartLinkCard = ({ user }: IProps): JSX.Element => {
  const [smartlinkProfile] = useState<ISmartLinkProfile>(SmartLinkProfile.create())

  useEffect(() => {
    if (user.uuid) {
      smartlinkProfile.load(user.uuid)
    }
  }, [])

  if (!user.uuid) {
    return <></>
  }

  return (
    <LoadingWrapper isLoading={smartlinkProfile.loading}>
      <SmartLinkProfileUpdate smartlinkProfile={smartlinkProfile} />
      <SmartLinkSocialPlatformsLinks smartlinkProfile={smartlinkProfile} />
    </LoadingWrapper>
  )
}

export default observer(SmartLinkCard)
