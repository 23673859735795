import { SERVICE_NAME } from '../../../constants'

import { IGenericResponse } from '../../api.types'
import clientWrapper from '../../clientWrapper'

export type IDeleteImageResponse = IGenericResponse<'deleteImage', boolean>

export async function deleteImage(type: string, uuid: string): Promise<IDeleteImageResponse> {
  const query = `
    mutation DeleteImage($type: ImageType!, $uuid: String!) {
      deleteImage(type: $type, uuid: $uuid)
    }
  `
  return clientWrapper(SERVICE_NAME.USERS, query, { type, uuid }) as Promise<IDeleteImageResponse>
}
