import { Instance, types } from 'mobx-state-tree'

import { APPROVAL_STATES, ASSET_TYPE } from '../../constants'
import { AssetUser } from './AssetUser.model'

export const AssetBasic = types.model({
  uuid: types.maybeNull(types.string),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
  deletedAt: types.maybeNull(types.string),
  upc: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  assetTypes: types.maybeNull(types.array(types.enumeration(Object.values(ASSET_TYPE)))),
  users: types.array(AssetUser),
  isrc: types.maybeNull(types.string),
  artist: types.maybeNull(types.string),
  genreUuids: types.maybeNull(types.array(types.string)),
  rightsHolders: types.maybeNull(types.string),
  label: types.maybeNull(types.string),
  assetId: types.maybeNull(types.string),
  customId: types.maybeNull(types.string),
  csAssetId: types.maybeNull(types.string),
  csCustomId: types.maybeNull(types.string),
  activeReferenceId: types.maybeNull(types.string),
  inactiveReferenceId: types.maybeNull(types.string),
  csSyncedAt: types.maybeNull(types.string),
  isClaimSynced: types.maybeNull(types.boolean),
  claimSyncedAt: types.maybeNull(types.string),
  earningSyncedAt: types.maybeNull(types.string),
  publishDate: types.maybeNull(types.string),
  publishingSyncedAt: types.maybeNull(types.string),
  version: types.maybeNull(types.number),
  sites: types.array(types.string),
  isActive: types.maybeNull(types.boolean),
  fileName: types.maybeNull(types.string),
  storagePath: types.maybeNull(types.string),
  uploadFinished: types.maybeNull(types.boolean),
  assetState: types.maybeNull(types.enumeration(Object.values(APPROVAL_STATES))),
  clientStatus: types.maybeNull(types.string),
  approvalComment: types.maybeNull(types.string),
  approvedAt: types.maybeNull(types.string),
  rejectedAt: types.maybeNull(types.string),
  approvalEmailSentAt: types.maybeNull(types.string),
  titleVersion: types.maybeNull(types.string),
  originalReleaseDate: types.maybeNull(types.string),
  preorderDate: types.maybeNull(types.string),
  releaseDate: types.maybeNull(types.string),
  metadataLanguage: types.maybeNull(types.string),
  bpm: types.maybeNull(types.number),
  iswc: types.maybeNull(types.string),
  audioLanguage: types.maybeNull(types.string),
  album: types.maybeNull(types.string),
  genre: types.maybeNull(types.string),
  isCover: types.maybeNull(types.boolean),
  srExist: types.maybeNull(types.boolean),
  description: types.maybeNull(types.string),
  channelId: types.maybeNull(types.string),
  downloadUrl: types.maybeNull(types.string),
  totalViews: types.maybeNull(types.number),
  totalClaims: types.maybeNull(types.number),
  fileSizeInBytes: types.maybeNull(types.number),
  isCompositionShare: types.maybeNull(types.boolean),
  createdBy: types.maybeNull(types.string),
  updatedBy: types.maybeNull(types.string),
  advisory: types.maybeNull(types.string),
  previewStart: types.maybeNull(types.number),
  duration: types.maybeNull(types.number),
  validationErrors: types.maybeNull(
    types.array(
      types.model({
        field: types.string,
        message: types.string,
      })
    )
  ),
  asJson: types.maybeNull(types.string),
  forceAssignIsrc: types.maybeNull(types.boolean),
  youtubeInactive: types.maybeNull(types.boolean),
})

export type IAssetBasic = Instance<typeof AssetBasic>
