import { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { observer } from 'mobx-react-lite'
import { MIN_PASSWORD_LENGTH } from '@epic-front/common/src/constants'
import {
  IResetPasswordResponse,
  resetPassword,
  IResetPasswordProps,
} from '@epic-front/common/src/api/users-api/users/resetPassword'

import FormInput from '../../blocks/inputs/FormInput'

interface IFormData {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

interface IProps {
  logout?: () => void
  btnClass?: string
}

const ResetPassword = (props: IProps): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()

  const schemaResolver = yupResolver(
    yup.object().shape({
      oldPassword: yup.string().required('Please enter old password'),
      newPassword: yup
        .string()
        .required('Please enter new password')
        .min(MIN_PASSWORD_LENGTH, `Password too short, min ${MIN_PASSWORD_LENGTH} chars`),

      confirmPassword: yup.string().oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
    })
  )

  const methods = useForm<IFormData>({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    resolver: schemaResolver,
  })

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = methods

  const onSubmit = async (yupFormData: IFormData) => {
    const variables: IResetPasswordProps = {
      newPassword: yupFormData?.newPassword,
      oldPassword: yupFormData?.oldPassword,
      clearAllSessions: true,
    }

    try {
      setLoading(true)

      const resp: IResetPasswordResponse = await resetPassword(variables)

      navigate('/account/login?reset=1')

      if (props.logout) {
        props.logout()
      }

      if (resp && resp.data.data?.resetPassword) {
        toast.success('Password successfully reset!')

        reset()
      }
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
      reset()
    }
  }

  return (
    <form noValidate name="reset-pass-form" id="reset-pass-form" onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Header as="h4">Change Password</Card.Header>
        <Card.Body>
          <Row className="g-2">
            <Col>
              <label htmlFor="oldPassword" className="mb-1">
                Old Password <span className="text-danger">*</span>
              </label>
              <FormInput
                type="password"
                name="oldPassword"
                placeholder="Enter old password"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
            <Col xxl={12}>
              <label htmlFor="newPassword" className="mb-1">
                New Password <span className="text-danger">*</span>
              </label>
              <FormInput
                type="password"
                name="newPassword"
                placeholder="Enter new password"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
            <Col xxl={12}>
              <label htmlFor="confirmPassword" className="mb-1">
                Confirm Password <span className="text-danger">*</span>
              </label>
              <FormInput
                type="password"
                name="confirmPassword"
                placeholder="Confirm password"
                register={register}
                errors={errors}
                control={control}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="py-3">
          <Button className={`w-100 ${props.btnClass}`} variant="primary" size="lg" type="submit" disabled={loading}>
            Reset Password
          </Button>
        </Card.Footer>
      </Card>
    </form>
  )
}

export default observer(ResetPassword)
