import { STORES } from '@epic-front/common'
import { Store } from '@vectopus/atlas-icons-react'
import IconApple from './IconApple'
import IconAmazon from './IconAmazon'
import IconPandora from './IconPandora'
import IconSoundCloud from './IconSoundCloud'
import IconSpotify from './IconSpotify'
import IconDeezer from './IconDeezer'
import IconYoutubeMusic from './IconYoutubeMusic'

interface IStoreIcon {
  store?: string | null
}

export const StoreIcon = ({ store }: IStoreIcon) => {
  if (!store) return <Store weight="bold" />

  switch (store) {
    case STORES.Apple:
      return <IconApple />
    case STORES.Amazon:
      return <IconAmazon />
    case STORES.Pandora:
      return <IconPandora />
    case STORES.SoundCloud:
      return <IconSoundCloud />
    case STORES.Spotify:
      return <IconSpotify />
    case STORES.Deezer:
      return <IconDeezer />
    case STORES.YoutubeMusic:
      return <IconYoutubeMusic />
    case STORES.Youtube:
      return <IconYoutubeMusic />

    default:
      return <Store weight="bold" />
  }
}
