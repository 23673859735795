import { applySnapshot, flow, Instance } from 'mobx-state-tree'
import { toast } from 'react-toastify'

import { ISmartLinkProfileResponse, smartlinkProfile } from '../../api/users-api/smartlink/smartlinkProfile'
import {
  IUpdateSmartLinkProfileResponse,
  updateSmartLinkProfile,
} from '../../api/users-api/smartlink/updateSmartLinkProfile'

import { createSmartLinkUrl, ICreateSmartLinkUrlResponse } from '../../api/users-api/smartlink/createSmartLinkUrl'
import { IImageBasic } from '../general/ImageBasic.model'
import { SmartLinkProfileBasic } from './SmartLinkProfileBasic.model'

export const SmartLinkProfile = SmartLinkProfileBasic.volatile(() => ({
  loading: false,
}))
  .volatile(() => ({
    profileImageUuid: '',
    bannerImageUuid: '',
  }))
  .views(self => ({
    getProfileUrlUuid() {
      if (self.profileImage?.uuid) {
        return self.profileImage.uuid
      }
      if (self.profileImageUuid.length > 0) {
        return self.profileImageUuid
      }
      return null
    },
    getBannerUrlUuid() {
      if (self.bannerImage?.uuid) {
        return self.bannerImage.uuid
      }
      if (self.bannerImageUuid.length > 0) {
        return self.bannerImageUuid
      }
      return null
    },
  }))
  .actions(self => ({
    setSlug(slug: string) {
      self.slug = slug
    },
    setName(name: string) {
      self.name = name
    },
    setBio(bio: string) {
      self.bio = bio
    },
    setProfileImage(img: IImageBasic | null) {
      self.profileImage = img
    },
    setBannerImage(img: IImageBasic | null) {
      self.bannerImage = img
    },
    setProfileImageUuid(profileImageUuid: string) {
      self.profileImageUuid = profileImageUuid
    },
    setBannerImageUuid(bannerImageUuid: string) {
      self.bannerImageUuid = bannerImageUuid
    },
  }))
  .actions(self => ({
    load: flow(function* (userUuid: string) {
      try {
        self.loading = true

        const resp: ISmartLinkProfileResponse = yield smartlinkProfile(userUuid)
        if (resp && resp.data.data?.smartlinkProfile) applySnapshot(self, resp.data.data?.smartlinkProfile)

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    update: flow(function* () {
      try {
        self.loading = true

        const resp: IUpdateSmartLinkProfileResponse = yield updateSmartLinkProfile({
          uuid: self.uuid || '',
          userUuid: self.userUuid || '',
          slug: self.slug || '',
          name: self.name || '',
          bio: self.bio || '',
          profileImage: self.getProfileUrlUuid(),
          bannerImage: self.getBannerUrlUuid(),
        })

        if (resp && resp.data.data?.updateSmartLinkProfile) {
          applySnapshot(self, resp.data.data?.updateSmartLinkProfile)
          toast.success('Profile successfully updated!')
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
    createSmartLinkUrl: flow(function* (platformSlug: string, url: string) {
      try {
        self.loading = true

        const resp: ICreateSmartLinkUrlResponse = yield createSmartLinkUrl({
          userUuid: self.userUuid || '',
          userSlug: self.slug || '',
          platformSlug,
          url,
        })

        if (resp && resp.data.data?.createSmartLinkUrl) {
          const updatedSelf = self
          updatedSelf.platformUrls.push(resp.data.data?.createSmartLinkUrl)
          applySnapshot(self, updatedSelf)
        }

        self.loading = false
      } catch (err) {
        console.error(err)
        self.loading = false
      }
    }),
  }))

export type ISmartLinkProfile = Instance<typeof SmartLinkProfile>
