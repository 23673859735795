import clientWrapper from '../../clientWrapper'
import { IGenericResponse } from '../../api.types'

import { SERVICE_NAME } from '../../../constants'

import { ISmartLinkProfileBasic } from '../../../models/smartlink/SmartLinkProfileBasic.model'

import { smartlinkUrlFragment } from '../fragments'

export type ICreateSmartlinkUrlProps = {
  userUuid: string
  userSlug: string
  platformSlug: string
  url: string
}

export type ICreateSmartLinkUrlResponse = IGenericResponse<'createSmartLinkUrl', ISmartLinkProfileBasic>

export async function createSmartLinkUrl(
  data: ICreateSmartlinkUrlProps,
  fragment: string = smartlinkUrlFragment
): Promise<ICreateSmartLinkUrlResponse> {
  const query = `
  mutation createSmartLinkUrl($data: SmartLinkPlatformUrlInput!) {
      createSmartLinkUrl(data: $data) {
          ${fragment}
      }
    }
  `

  return (await clientWrapper(SERVICE_NAME.USERS, query, { data })) as ICreateSmartLinkUrlResponse
}
